<template>
  <div class="news-integrator__wrapper">
    <div class="news-integrator">
      <p class="news-integrator__title">{{ news.title }}</p>
      <p v-html="formattedText" class="news-integrator__text"></p>
      <div class="news-integrator__close">
        <CloseIcon class="close" @click.native="closeModal" />
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from './assets/CloseIcon'
import { mapActions } from 'vuex'

export default {
  components: {
    CloseIcon
  },
  props: {
    news: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formattedText() {
      return this.news.message
        .replace(/\n\n/g, '<br/><br/>')
        .replace(/\n/g, '<br/>')
    }
  },
  methods: {
    ...mapActions({
      sendMessageNews: 'properties/sendMessageNews'
    }),
    closeModal() {
      this.sendMessageNews(this.news.id)
      this.$emit('close-news-modals')
    }
  }
}
</script>

<style lang="scss" scoped>
.news-integrator__wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.news-integrator {
  position: absolute;
  width: 440px;
  max-height: 824px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px #00000029;
  padding: 18px 24px;
  margin-top: 60px 0;
  z-index: 1;
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 16px;
    color: #292929;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #171717;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
  }
}
</style>
